function setCookie(key, value) {
  var expires = new Date();
  expires.setTime(expires.getTime() + (1 * 24 * 60 * 60 * 1000));
  document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

function getCookie(key) {
  var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  return keyValue ? keyValue[2] : null;
}

(function ($) {
  
  if ($('.site-banner').length > 0 && getCookie('hide-banner') !== '1') {
    console.log(getCookie('hide-banner'));
    // var bannerHeight = $('.site-banner').outerHeight();
    // $('body').addClass('has-banner');
    // $('body.has-banner').css('padding-top', bannerHeight+'px');

    $('.site-banner__close').on('click', function () {
      $(this).parent().hide();
      $('body').removeClass('has-banner');

      setCookie('hide-banner', '1');
    });

  } else {
    $('.site-banner').hide();
    $('body').removeClass('has-banner');
  }

})(jQuery); 